<script setup>
const props = defineProps({
  id: {
    type: String,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['input'])
const value = defineModel()

const onInput = () => {
  emit('input', !value)
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-checkbox.css';
</style>

<template>
  <div class="nu-checkbox mb-3">
    <div class="d-flex">
      <input
        :id="id"
        type="checkbox"
        v-model="value"
        v-bind="$attrs"
        @input="onInput"
      />
      <span class="check-mark"></span>
      <label :for="id">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
    </div>
    <div
      class="error-message"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
